import React, { useState, useEffect } from "react";
import { Layout, Menu, Dropdown } from "antd";
import { Link } from "react-router-dom";
import { AmplifySignOut } from "@aws-amplify/ui-react";
import { Auth } from "aws-amplify";

const { Header } = Layout;

const Navbar = () => {
  const [isAdmin, setIsAdmin] = useState(false);

  useEffect(() => {
    checkAdmin();
  }, []);

  const checkAdmin = async () => {
    const userInfo = await Auth.currentUserInfo();
    const groups =
      userInfo.signInUserSession.idToken.payload["cognito:groups"] || [];
    setIsAdmin(groups.includes("Admin"));
  };

  const menu = (
    <Menu>
      <Menu.Item key="4">
        <Link to="/my-account/settings">Settings</Link>
      </Menu.Item>
      <Menu.Item key="5">
        <Link to="/my-account/info">Info</Link>
      </Menu.Item>
      {isAdmin && (
        <Menu.Item key="6">
          <Link to="/my-account/admin">Admin</Link>
        </Menu.Item>
      )}
    </Menu>
  );

  return (
    <Header>
      <div className="logo" />
      <Menu theme="dark" mode="horizontal" defaultSelectedKeys={["1"]}>
        <Menu.Item key="1">
          <Link to="/">Home</Link>
        </Menu.Item>
        <Menu.Item key="2">
          <Link to="/about">About</Link>
        </Menu.Item>
        <Menu.Item key="3">
          <Dropdown overlay={menu}>
            <a className="ant-dropdown-link" onClick={(e) => e.preventDefault()}>
              My Account
            </a>
          </Dropdown>
        </Menu.Item>
        <Menu.Item key="7">
          <AmplifySignOut />
        </Menu.Item>
      </Menu>
    </Header>
  );
};

export default Navbar;

