import React from "react";

const InfoPage = () => {
  return (
    <div>
      <h1>Info</h1>
      {/* Add the components to display general information here */}
    </div>
  );
};

export default InfoPage;
