import React from "react";
import { Route, Switch } from "react-router-dom";
import AboutPage from "../pages/AboutPage";
import NotFoundPage from "../pages/NotFoundPage";
import EditTodoPage from "../pages/EditTodoPage";
import SettingsPage from "../pages/SettingsPage";
import InfoPage from "../pages/InfoPage";
import AdminPage from "../pages/AdminPage";

const Routes = () => {
  return (
    <section>
      <Switch>
        {/* Add the new routes here */}
        <Route exact path="/my-account/settings" component={SettingsPage} />
        <Route exact path="/my-account/info" component={InfoPage} />
        <Route exact path="/my-account/admin" component={AdminPage} />
        <Route exact path="/about" component={AboutPage} />
        <Route exact path="/edit/:id" component={EditTodoPage} />
        <Route component={NotFoundPage} />
      </Switch>
    </section>
  );
};

export default Routes;
