import React, { useState, useEffect } from 'react';
import { Auth } from 'aws-amplify';

const UserAttributesForm = () => {
  const [user, setUser] = useState(null);
  const [attributes, setAttributes] = useState({});

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const currentUser = await Auth.currentAuthenticatedUser();
        setUser(currentUser);
        setAttributes({
          email: currentUser.attributes.email,
          phone_number: currentUser.attributes.phone_number,
          display_name: currentUser.attributes['custom:display_name'],
          timezone: currentUser.attributes['custom:timezone'],
          display_unit: currentUser.attributes['custom:display_unit'],
          email_notifications: currentUser.attributes['custom:email_notifications'],
        });
      } catch (error) {
        console.error('Error fetching user:', error);
      }
    };
    fetchUser();
  }, []);

  const updateAttribute = async (attribute, value) => {
    try {
      await Auth.updateUserAttributes(user, { [attribute]: value });
      if (attribute === 'email' || attribute === 'phone_number') {
        await Auth.verifyCurrentUserAttribute(attribute);
      }
      alert(`Successfully updated ${attribute}`);
    } catch (error) {
      console.error(`Error updating ${attribute}:`, error);
      alert(`Error updating ${attribute}`);
    }
  };

  const handleChange = (attribute, event) => {
    setAttributes({ ...attributes, [attribute]: event.target.value });
  };

  const handleSubmit = (attribute, event) => {
    event.preventDefault();
    updateAttribute(attribute, attributes[attribute]);
  };

  if (!user) return null;

  return (
    <div>
      <form onSubmit={(event) => handleSubmit('email', event)}>
        <label>Email:</label>
        <input
          type="email"
          value={attributes.email}
          onChange={(event) => handleChange('email', event)}
        />
        <button type="submit">Update Email</button>
      </form>

      <form onSubmit={(event) => handleSubmit('phone_number', event)}>
        <label>Phone Number:</label>
        <input
          type="tel"
          value={attributes.phone_number}
          onChange={(event) => handleChange('phone_number', event)}
        />
        <button type="submit">Update Phone Number</button>
      </form>

      <form onSubmit={(event) => handleSubmit('custom:display_name', event)}>
        <label>Display Name:</label>
        <input
          type="text"
          value={attributes.display_name}
          onChange={(event) => handleChange('custom:display_name', event)}
        />
        <button type="submit">Update Display Name</button>
      </form>

      <form onSubmit={(event) => handleSubmit('custom:timezone', event)}>
        <label>Timezone:</label>
        <input
          type="text"
          value={attributes.timezone}
          onChange={(event) => handleChange('custom:timezone', event)}
        />
        <button type="submit">Update Timezone</button>
      </form>

      <form onSubmit={(event) => handleSubmit('custom:display_unit', event)}>
        <label>Display Unit:</label>
        <input
          type="text"
          value={attributes.display_unit}
          onChange={(event) => handleChange('custom:display_unit', event)}
        />
        <button type="submit">Update Display Unit</button>
      </form>

      <form onSubmit={(event) => handleSubmit('custom:email_notifications', event)}>
        <label>Email Notifications:</label>
        <select
          value={attributes.email_notifications}
          onChange={(event) => handleChange('custom:email_notifications', event)}
        >
          <option value="true">Yes</option>
          <option value="false">No</option>
        </select>
        <button type="submit">Update Email Notifications</button>
      </form>
    </div>
  );
};

export default UserAttributesForm;
