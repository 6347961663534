import React, { useEffect, useState } from "react";
import { Auth } from "aws-amplify";
import { useHistory } from "react-router-dom";

const AdminPage = () => {
  const [isAdmin, setIsAdmin] = useState(false);
  const history = useHistory();

  useEffect(() => {
    checkAdmin();
  }, []);

  const checkAdmin = async () => {
    const userInfo = await Auth.currentUserInfo();
    const groups =
      userInfo.signInUserSession.idToken.payload["cognito:groups"] || [];
    const adminStatus = groups.includes("Admin");
    setIsAdmin(adminStatus);
    if (!adminStatus) {
      history.push("/404");
    }
  };

  return (
    isAdmin && (
      <div>
        <h1>Admin</h1>
        {/* Add the components to manage admin-related features here */}
      </div>
    )
  );
};

export default AdminPage;

